// border
.fancy {
    text-align: center;
    white-space: nowrap;
    width: 100%;
    display: block;
    margin-bottom: 10px;
    // margin-left: 10.5%;
    // margin-right: 10.5%;
    margin-top: 10px;
    overflow: hidden;
}

.fancy > span {
    display: inline-block;
    position: relative;
    color: #2d3748;
}

.fancy > span:before {
    margin-right: 15px;
    right: 100%;
}

.fancy > span:after {
    left: 100%;
    margin-left: 15px;
}

.fancy > span:before,
.fancy > span:after {
    background: #e2e8f0;
    content: '';
    height: 1px;
    position: absolute;
    top: 50%;
    width: 9999px;
}
