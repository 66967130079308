$color-primary: #005078;
$color-secondary: #e74e42;

// normal state
.btn-burger {
    cursor: pointer;
    position: relative;
    height: 30px;
    width: 30px;
    display: block;
    z-index: 9;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    pointer-events: all;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    span {
        background-color: white;
        position: absolute;
        top: 50%;
        right: 2px;
        left: 2px;
        height: 2px;
        width: auto;
        margin-top: -1px;
        transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
            opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
            background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    span.btn-burger__line-1 {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }

    span.btn-burger__line-2 {
        transform-origin: 100% 50%;
        transform: scaleX(0.8);
    }

    span.btn-burger__line-3 {
        transform: translateY(8px);
    }

    //   &:hover {
    //     span {
    //       background-color: $color-secondary;
    //     }
    //   }
}

// active state
.btn-burger.active {
    transform: rotate(-180deg);

    & span.btn-burger__line-1 {
        transform: rotate(45deg);
    }

    & span.btn-burger__line-2 {
        opacity: 0;
    }

    & span.btn-burger__line-3 {
        transform: rotate(-45deg);
    }
}
